import React, { useState, useEffect } from 'react';
import cls from './payForm.module.scss';
import { ReactComponent as CloseSVG } from '../../assets/icons/close.svg';

export const PayForm = ({ toggleIsOpen, onClose }) => {
    const [isTinkoffLoaded, setTinkoffLoaded] = useState(false);

    const [formData, setFormData] = useState({
        amount: '',
        description: '',
        name: '',
        email: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isTinkoffLoaded) {
            console.error('Библиотека Tinkoff не загружена');
            return;
        }

        const { description, amount, email, phone } = formData;

        if (!email && !phone) {
            alert('Поле E-mail или Phone не должно быть пустым');
            return;
        }

        const receipt = JSON.stringify({
            EmailCompany: 'activetranzit@bk.ru',
            Taxation: 'osn',
            FfdVersion: '1.2',
            Items: [
                {
                    Name: description || 'Оплата',
                    Price: `${amount}00`,
                    Quantity: 1.0,
                    Amount: `${amount}00`,
                    PaymentMethod: 'full_prepayment',
                    PaymentObject: 'service',
                    Tax: 'none',
                    MeasurementUnit: 'pc',
                },
            ],
        });

        // pay({ ...formData, receipt });
        const TPF = {
            ...formData,
            receipt,
        };

        if (window.pay) {
            window.pay(TPF); // Вызов метода из подключенной библиотеки
        } else {
            console.error('Метод pay не найден. Убедитесь, что tinkoff_v2.js подключён.');
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js';
        script.async = true;
        script.onload = () => setTinkoffLoaded(true);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // const pay = (data) => {
    //     console.log('Submitting payment data:', data);
    //     // Здесь можно добавить логику отправки данных на сервер
    // };

    return (
        <div className={cls.form}>
            <CloseSVG className={cls.form__close} onClick={onClose} />
            <form className={cls.payform_tbank} name="payform-tbank" onSubmit={handleSubmit}>
                <input
                    className={cls.payform_tbank_row}
                    type="hidden"
                    name="terminalkey"
                    value="1732467302863DEMO"
                />
                <input className={cls.payform_tbank_row} type="hidden" name="frame" value="true" />
                <input className={cls.payform_tbank_row} type="hidden" name="language" value="ru" />
                <input
                    className={cls.payform_tbank_row}
                    type="text"
                    placeholder="Сумма заказа"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    required
                />
                <input
                    className={cls.payform_tbank_row}
                    type="hidden"
                    placeholder="Номер заказа"
                    name="order"
                />
                <input
                    className={cls.payform_tbank_row}
                    type="text"
                    placeholder="Описание заказа"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                />
                <input
                    className={cls.payform_tbank_row}
                    type="text"
                    placeholder="ФИО плательщика"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
                <input
                    className={cls.payform_tbank_row}
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                />
                <input
                    className={cls.payform_tbank_row}
                    type="tel"
                    placeholder="Контактный телефон"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                />
                <input
                    className={`${cls.payform_tbank_row} ${cls.payform_tbank_btn}`}
                    type="submit"
                    value="Оплатить"
                />
            </form>
        </div>
    );
};
