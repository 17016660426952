import cls from './Header.module.scss';
// import { ReactComponent as Logo } from '../../img/logo/logo-dark.svg';
// import { NavBar } from './nav/NavBar';
import { Layout } from '../../shared/layout/Layout';
import { HeaderVideo } from '../headerVideo/HeaderVideo';
import { Modal } from '../../shared/Modal/Modal';
import { Form } from '../form/Form';
import { useState } from 'react';
import { PayForm } from '../payForm/payForm';

export const Header = () => {
    const [isOpen, toggleIsOpen] = useState(false);
    const [isOpenPayForm, toggleIsOpenPayForm] = useState(false);
    return (
        <>
            <div className={cls.header}>
                <HeaderVideo />
                <Layout>
                    <div className={cls.header__wrapper}>
                        {/* <div className={cls.header__logo}>
                            <Logo />
                        </div> */}
                        {/* <NavBar /> */}
                        <h3>ТОРГОВЫЕ РОБОТЫ</h3>

                        <p>Разработка и создание торговых экспертов.</p>
                        <p>Обзоры различных бесплатных советников.</p>
                        <p>Индивидуальное обучение алготрейдингу.</p>
                        <button onClick={() => toggleIsOpen(true)} className={cls.header__btn}>
                            Задать вопрос
                        </button>
                        <button onClick={() => {toggleIsOpenPayForm(true)}} className={cls.header__btn}>
                            Оплатить
                        </button>
                    </div>
                </Layout>
            </div>
            <Modal isOpen={isOpen} onClose={toggleIsOpen}>
                <Form toggleIsOpen={toggleIsOpen}/>
            </Modal>
            <Modal isOpen={isOpenPayForm} onClose={toggleIsOpenPayForm}>
                <PayForm toggleIsOpen={toggleIsOpenPayForm}/>
            </Modal>
        </>
    );
};
